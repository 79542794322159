<template>
  <ThePopup :isActive="isActive">
    <div class="col-11 col-md-6 col-lg-5 col-xl-4 p-0 card_item" :class="{'col-xl-9 col-lg-8 col-md-10': isActive === 'login', 'col-12 fullcard': isActive !== 'login' && isActive !== 'forgot'}">
      <div class="row mx-0 h-100 justify-content-center">
        <div class="col-12 px-0">
          <ContentWithBackgroundAndFooter>
            <div class="row m-0 justify-content-end lh-1">
              <div class="col-auto text-end cursor p-2 close" @click="cancel">
                <ios-close-icon class="inline-icon big" />
              </div>
            </div>
            <div class="row m-0 justify-content-center" v-if="isActive !== 'signup' && isActive !== 'login' && isActive !== 'finder'">
              <div class="col-6 text-center p-0 mb-3">
                <img :src="logo_dark" class="img-fluid">
              </div>
            </div>
            <div class="row m-0 justify-content-center" v-if="isActive === 'signup'">
              <div class="col-auto text-center px-0 pb-3 border_bottom mb-3">
                <div class="card_title">
                  DONOR REGISTRATION
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-12 p-0">
                <!-- <transition-group name="fade" mode="out-in"> -->
                  <Login @signup="isActive = 'signup'" @finder="isActive = 'finder'" @forgot="isActive = 'forgot'" v-if="isActive === 'login'" @close="closePopup" @loggedIn="loggedIn" @cancel="cancel" :isRedirect="redirect" />
                  <Signup @login="isActive = 'login'" v-else-if="isActive === 'signup'" @close="closePopup" @loggedIn="loggedIn" :isRedirect="redirect"/>
                  <Forgot @login="isActive = 'login'" v-else-if="isActive === 'forgot'" :isRedirect="redirect"/>
                  <ResetPassword v-else-if="isActive === 'reset'" @login="isActive = 'login'" :isRedirect="redirect"/>
                  <DetailsFinder @login="isActive = 'login'" @signup="isActive = 'signup'" v-else-if="isActive === 'finder'" :isRedirect="redirect"/>
                <!-- </transition-group> -->
              </div>
            </div>
          </ContentWithBackgroundAndFooter>
        </div>
      </div>
    </div>
  </ThePopup>
</template>

<script>
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
export default {
  components: {
    Login: defineAsyncComponent(() => import('./views/Login.vue')),
    Signup: defineAsyncComponent(() => import('./views/Signup.vue')),
    Forgot: defineAsyncComponent(() => import('./views/Forgot.vue')),
    ResetPassword: defineAsyncComponent(() => import('./views/ResetPassword.vue')),
    DetailsFinder: defineAsyncComponent(() => import('./views/DetailsFinder.vue')),
    ThePopup: defineAsyncComponent(() => import('./components/styling/ThePopup.vue')),
    ContentWithBackgroundAndFooter: defineAsyncComponent(() => import('./components/styling/ContentWithBackgroundAndFooter.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))

  },
  name: 'Login Popup',
  props: ['user', 'isReset', 'redirect'],
  data () {
    return {
      isActive: 'login',
      logo: process.env.VUE_APP_LOGO,
      logo_dark: process.env.VUE_APP_LOGO_DARK
    }
  },
  mounted () {
    document.body.style.overflow = 'hidden'
    if (this.isReset === 'isReset') {
      this.isActive = 'reset'
    }
    this.setLoginPopupStatus(true)
  },
  unmounted () {
    document.body.style.overflow = ''
    this.setLoginPopupStatus(false)
  },
  methods: {
    ...mapActions([
      'setLoginPopupStatus', 'setUserStatus'
    ]),
    async setLoginStatus (val) {
      await this.setUserStatus(val)
    },
    closePopup () {
      this.$emit('close')
    },
    loggedIn () {
      this.$emit('loggedIn')
    },
    cancel () {
      this.setLoginStatus(null)
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.card_item {
  /* background-color: #fff; */
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.fullcard {
  height: 100vh;
  max-height: 100vh;
  border-radius: 0;
}

.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
@media (min-width: 992px) {
  .fullcard {
    /* background-color: #fff; */
    border-radius: 22px;
    box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
    max-height: 90vh;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
